<template>
  <section id="workflow-users-list">
    <el-tabs type="card" @tab-click="handleClick" v-model="activeTab">
      <el-tab-pane label="Need to be Approved" name="need_to_be_approved">
        <span slot="label">
          Need to be Approved
          <div class="count-circle" style="background-color: #007bff">
            {{ needToApproveCount }}
          </div>
        </span>
      </el-tab-pane>
      <el-tab-pane label="Hold" name="hold">
        <span slot="label">
          Hold
          <div class="count-circle" style="background-color: #007bff">
            {{ holdCount }}
          </div>
        </span>
      </el-tab-pane>
      <el-tab-pane label="Waiting for others" name="waiting_for_others">
        <span slot="label">
          Waiting for others
          <div class="count-circle" style="background-color: #e6a23c">
            {{ needToApproveOthersCount }}
          </div>
        </span>
      </el-tab-pane>
      <el-tab-pane label="Approved" name="approved">
        <span slot="label">
          Approved
          <div class="count-circle" style="background-color: #67c23a">
            {{ approvedCount }}
          </div>
        </span>
      </el-tab-pane>
      <el-tab-pane label="Rejected" name="rejected">
        <span slot="label">
          Rejected
          <div class="count-circle" style="background-color: #f56c6c">
            {{ rejectedCount }}
          </div>
        </span>
      </el-tab-pane>
    </el-tabs>
    <div class="">
      <div class="vue-data-table-default">
        <data-tables-server
          :data="data"
          :total="total"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :pagination-props="paginationProps"
          @query-change="loadData"
          v-loading="loading"
          v-if="!getIsMobile"
        >
          <el-table-column fixed="left" min-width="200" label="Form Name">
            <template slot-scope="scope">
              <div
                v-if="
                  scope.row &&
                  scope.row.formbuilders_details_id &&
                  scope.row.formbuilders_details_id.name
                "
                style="word-break: break-word"
              >
                {{ scope.row.formbuilders_details_id.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="User name" min-width="180">
            <template slot-scope="scope">
              <div class="d-flex" v-if="!isAnonymousForm(scope.row)">
                <div style="word-break: break-word">
                  {{ getUserName(scope.row) }}
                </div>
              </div>
              <div v-else>
                <el-popover
                  placement="top-start"
                  title="User info"
                  width="300"
                  trigger="hover"
                  v-if="
                    scope.row &&
                    scope.row.formbuilders_details_id &&
                    scope.row.formbuilders_details_id.primary_fields
                  "
                >
                  <div slot="reference">
                    <i class="el-icon-info"></i>
                    <span class="d-inline-block pl-10"> ANONYMOUS </span>
                  </div>
                  <div>
                    <div
                      v-for="op in scope.row.formbuilders_details_id
                        .primary_fields"
                      :key="op + '_key'"
                    >
                      {{ getFormbuilderFieldValue(op, scope.row) }}
                    </div>
                  </div>
                </el-popover>
                <div v-else>ANONYMOUS</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="User email/User IP Address" min-width="201">
            <template slot-scope="scope">
              <div class="d-flex" v-if="!isAnonymousForm(scope.row)">
                <div>{{ getUserEmail(scope.row) }}</div>
              </div>
              <div v-else>
                <div>{{ scope.row.ip_address }}</div>
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="action" min-width="100px">
            <template slot-scope="scope">
              <el-dropdown>
                <span class="el-dropdown-link">
                  Actions
                  <i class="el-icon-caret-bottom el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <a
                    @click="onHold(scope.row)"
                    v-if="
                      checkIsApproverUser(scope.row) &&
                      scope.row.approval_status == 'PENDING'
                    "
                  >
                    <el-dropdown-item icon="el-icon-video-pause" command="view"
                      >Hold</el-dropdown-item
                    >
                  </a>
                  <a
                    @click="onApprove(scope.row)"
                    v-if="checkIsApproverUser(scope.row)"
                  >
                    <el-dropdown-item icon="el-icon-finished" command="view"
                      >Approve</el-dropdown-item
                    >
                  </a>
                  <a
                    @click="showRejectConfirm(scope.row)"
                    v-if="checkIsApproverUser(scope.row)"
                  >
                    <el-dropdown-item icon="el-icon-circle-close" command="view"
                      >Reject</el-dropdown-item
                    >
                  </a>
                  <a
                    @click="viewFormbuilderData(scope.row)"
                    style="border: none"
                  >
                    <el-dropdown-item icon="el-icon-view" command="view"
                      >View</el-dropdown-item
                    >
                  </a>
                  <a
                    v-if="checkIsApproverUser(scope.row)"
                    @click="editWorkUserData(scope.row)"
                    style="border: none"
                  >
                    <el-dropdown-item icon="el-icon-edit-outline" command="edit"
                      >Edit</el-dropdown-item
                    >
                  </a>
                  <!-- <a @click="onDelete(scope.row)" style="border: none">
                    <el-dropdown-item icon="el-icon-delete" command="delete"
                      >Delete</el-dropdown-item
                    >
                  </a> -->
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>

          <el-table-column
            v-if="hasIntervals"
            label="Submitted For"
            min-width="150"
          >
            <template slot-scope="scope">
              <div>
                <span class="black-coral sm-80" v-if="scope.row.time_interval">
                  {{
                    scope.row.time_interval
                      | moment(fequencyDateMap[intervalType])
                  }}
                </span>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Approval Status" min-width="300">
            <template slot-scope="scope">
              <div
                class="success"
                v-if="scope.row.approval_status == 'APPROVED'"
              >
                <el-popover
                  placement="top-start"
                  title="Approval details:"
                  width="300"
                  trigger="hover"
                >
                  <div v-if="scope.row.priority_based">
                    <span
                      v-if="
                        getApprovedUser(scope.row) &&
                        getApprovedUser(scope.row).approved_by
                      "
                      >Approved by: {{ getApprovedUser(scope.row).approved_by }}
                    </span>
                    <br />
                    <span
                      v-if="
                        getApprovedUser(scope.row) &&
                        getApprovedUser(scope.row).approved_at
                      "
                      >Approved on:
                      {{
                        convertToLocalDateTime(
                          getApprovedUser(scope.row).approved_at
                        ) | globalDateTimeFormat
                      }}</span
                    >
                  </div>
                  <div v-else>
                    <ul>
                      <li
                        v-for="approver in getApprovedUser(scope.row)"
                        :key="approver._id"
                        class="list-style"
                      >
                        <div
                          v-if="approver.approved_by && approver.approved_at"
                        >
                          <i class="el-icon-user-solid icon-style"></i>
                          Approved by:<span class="approver-text">{{
                            " " + approver.approved_by + ","
                          }}</span
                          ><br />
                          <i class="el-icon-time icon-style"></i>
                          Approved on:<span class="approver-text">{{
                            (" " + convertToLocalDateTime(approver.approved_at))
                              | globalDateTimeFormat
                          }}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div slot="reference">
                    <i class="el-icon-success"></i>
                    <span class="d-inline-block pl-10">
                      {{
                        scope.row.approval_status == "APPROVED"
                          ? "Approved"
                          : ""
                      }}
                    </span>
                  </div>
                </el-popover>
              </div>
              <div
                class="error"
                v-else-if="scope.row.approval_status == 'REJECTED'"
              >
                <el-popover
                  placement="top-start"
                  title="Rejection details:"
                  width="300"
                  trigger="hover"
                >
                  <div>
                    <span
                      v-if="
                        getRejectedUser(scope.row) &&
                        getRejectedUser(scope.row).rejected_by
                      "
                      >Rejected by: {{ getRejectedUser(scope.row).rejected_by }}
                    </span>
                    <br />
                    <span
                      v-if="
                        getRejectedUser(scope.row) &&
                        getRejectedUser(scope.row).rejected_at
                      "
                      >Rejected on:
                      {{
                        convertToLocalDateTime(
                          getRejectedUser(scope.row).rejected_at
                        ) | globalDateTimeFormat
                      }}</span
                    >
                    <br />
                    <span
                      v-if="
                        getRejectedUser(scope.row) &&
                        getRejectedUser(scope.row).rejected_reason
                      "
                      style="word-break: break-word"
                    >
                      Reason:
                      {{ getRejectedUser(scope.row).rejected_reason }}
                    </span>
                  </div>
                  <div slot="reference" class="expired-div">
                    <i class="el-icon-error"></i>
                    <span class="d-inline-block pl-10">
                      {{
                        scope.row.approval_status == "REJECTED"
                          ? "Rejected"
                          : ""
                      }}
                    </span>
                  </div>
                </el-popover>
              </div>
              <div
                :class="
                  getReviewerData(scope.row) &&
                  getReviewerData(scope.row).status == 'REJECTED'
                    ? 'error'
                    : 'success'
                "
                v-else-if="checkReviewer(scope.row)"
              >
                <el-popover
                  placement="top-start"
                  :title="getReviewerData(scope.row).title"
                  width="300"
                  trigger="hover"
                >
                  <div>
                    <span v-if="getReviewerData(scope.row).label1"
                      >{{ getReviewerData(scope.row).label1 }}
                    </span>
                    <br />
                    <span v-if="getReviewerData(scope.row).date"
                      >On:
                      {{
                        getReviewerData(scope.row).date | globalDateTimeFormat
                      }}</span
                    >
                    <br />
                    <span
                      v-if="getReviewerData(scope.row).reason"
                      style="word-break: break-word"
                    >
                      Reason:
                      {{ getReviewerData(scope.row).reason }}
                    </span>
                  </div>
                  <div slot="reference">
                    <i
                      :class="
                        getReviewerData(scope.row).status == 'REJECTED'
                          ? 'el-icon-error'
                          : 'el-icon-success'
                      "
                    ></i>
                    <span class="d-inline-block" style="word-break: break-word">
                      {{
                        getReviewerData(scope.row).status +
                        " by " +
                        getReviewerData(scope.row).user_name
                      }}
                    </span>
                  </div>
                </el-popover>
              </div>
              <div class="warning d-flex" v-else>
                <!-- <i class="el-icon-time"></i> -->
                <span class="d-inline-block pl-10">{{
                  scope.row.approval_status == "HOLD" ? "Hold" : "Pending"
                }}</span>
                <formbuilderLogs
                  class="warning"
                  :currentFormbuilderData="scope.row"
                  :allEntitiesData="allEntitiesData"
                  :allCompanyUserTypesData="allCompanyUserTypesData"
                  v-on:reload-table="fetchAllEmployee"
                ></formbuilderLogs>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Sent Status" min-width="150">
            <template slot-scope="scope">
              <div>
                <span
                  v-if="scope.row.sent_at"
                  class="d-block yale-blue sm-80 line-height-1"
                  >Sent On</span
                >
                <span class="black-coral sm-80" v-if="scope.row.sent_at">
                  {{ scope.row.sent_at | moment("MM-DD-YYYY HH:mm") }}
                </span>

                <span
                  v-if="!scope.row.sent_at"
                  class="d-block yale-blue sm-80 line-height-1"
                  >Not Send</span
                >
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Completion Status" width="250" fixed="right">
            <template slot-scope="scope">
              <div
                class="warning"
                v-if="
                  scope.row.formbuilder_data_status == 'PENDING' ||
                  scope.row.formbuilder_data_status == 'SENT'
                "
              >
                <i class="el-icon-refresh"></i>
                <span class="d-inline-block pl-10">
                  {{
                    scope.row.formbuilder_data_status == "PENDING" ||
                    scope.row.formbuilder_data_status == "SENT"
                      ? "Pending"
                      : ""
                  }}
                </span>
              </div>

              <div
                class="success"
                v-else-if="scope.row.formbuilder_data_status == 'COMPLETED'"
              >
                <i class="el-icon-success"></i>
                <span class="d-inline-block pl-10">
                  {{
                    scope.row.formbuilder_data_status == "COMPLETED"
                      ? "Completed"
                      : ""
                  }}
                </span>
              </div>

              <div
                class="expired-div"
                v-else-if="scope.row.formbuilder_data_status == 'EXPIRED'"
              >
                <i class="el-icon-error"></i>
                <span class="d-inline-block pl-10">
                  {{
                    scope.row.formbuilder_data_status == "EXPIRED"
                      ? "Expired"
                      : ""
                  }}
                </span>
              </div>

              <div v-else>Pending</div>
            </template>
          </el-table-column>
        </data-tables-server>
        <data-tables-server
          :data="data"
          :total="total"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :pagination-props="paginationProps"
          @query-change="loadData"
          v-loading="loading"
          v-else-if="getIsMobile"
        >
          <el-table-column type="expand">
            <template slot-scope="scope">
              <div class="expand-column-item" v-if="hasIntervals">
                <div class="expand-column-item-label">Submitted For</div>
                <div class="expand-column-item-content">
                  {{
                    scope.row.time_interval
                      | moment(fequencyDateMap[intervalType])
                  }}
                </div>
              </div>
              <div class="expand-column-item">
                <div class="expand-column-item-label">Sent Status</div>
                <div class="expand-column-item-content">
                  {{ scope.row.sent_at | moment("MM-DD-YYYY HH:mm") }}
                </div>
              </div>
              <div class="expand-column-item">
                <div class="expand-column-item-label">Completion Status</div>
                <div class="expand-column-item-content">
                  <div class="warning">
                    <i
                      class="el-icon-refresh"
                      v-if="
                        scope.row.formbuilder_data_status == 'PENDING' ||
                        scope.row.formbuilder_data_status == 'SENT'
                      "
                    ></i>
                    <span class="d-inline-block pl-10">
                      {{
                        scope.row.formbuilder_data_status == "PENDING" ||
                        scope.row.formbuilder_data_status == "SENT"
                          ? "Pending"
                          : ""
                      }}
                    </span>
                  </div>

                  <div class="success">
                    <i
                      class="el-icon-success"
                      v-if="scope.row.formbuilder_data_status == 'COMPLETED'"
                    ></i>
                    <span class="d-inline-block pl-10">
                      {{
                        scope.row.formbuilder_data_status == "COMPLETED"
                          ? "Completed"
                          : ""
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="50" fixed="left">
            <template>
              <div>
                <img src="@/assets/img/avatar-2.svg" alt="avatar" width="30" />
              </div>
            </template>
          </el-table-column>
          <el-table-column label="User" min-width="300">
            <template slot-scope="scope">
              <div class="d-flex">
                <div class="pl-15">{{ getUserEmail(scope.row) }}</div>
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="action" min-width="100px">
            <template slot-scope="scope">
              <el-dropdown>
                <span class="el-dropdown-link">
                  Actions
                  <i class="el-icon-caret-bottom el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <a
                    @click="viewFormbuilderData(scope.row)"
                    style="border: none"
                  >
                    <el-dropdown-item icon="el-icon-view" command="view"
                      >View</el-dropdown-item
                    >
                  </a>
                  <a @click="editWorkUserData(scope.row)" style="border: none">
                    <el-dropdown-item icon="el-icon-edit-outline" command="edit"
                      >Edit</el-dropdown-item
                    >
                  </a>
                  <a @click="onDelete(scope.row)" style="border: none">
                    <el-dropdown-item icon="el-icon-edit-outline" command="edit"
                      >Delete</el-dropdown-item
                    >
                  </a>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </data-tables-server>
      </div>
    </div>
    <el-dialog
      :destroy-on-close="true"
      :visible.sync="reasonForRejectModal"
      title="Reason for reject"
    >
      <el-input
        type="textarea"
        :rows="2"
        placeholder="Reason for reject"
        v-model="reasonForReject"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="rejectFormbuilder(reasonForRejectData)"
          type="primary"
          :disabled="!reasonForReject.length"
          >Submit</el-button
        >
        <el-button @click="reasonForRejectModal = false">Cancel</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import config from "@/config/app";
import FormbuilderHelper from "@/mixins/FormbuilderHelper.js";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper.js";
// import formBuilderSharingSettings from "../widgets/formBuilderSharingSettings.vue";
export default {
  name: "FormbuilderUsersData",
  components: {
    formbuilderLogs: () => import("@/components/widgets/formbuilderLogsWidget"),
    // formBuilderSharingSettings,
  },
  mixins: [FormbuilderHelper, TemplateBuilderHelper],
  data() {
    return {
      formbuilder_code: null,
      loading: false,
      editDailogVisible: false,
      selectedOfferLetterTemplate: "",
      total: 0,
      currentPage: 1,
      pageSize: 0,
      data: [],
      addDialogVisible: false,
      previewDialogVisible: false,
      selectedOfferLetter: null,
      viewOfferLetterVisible: false,
      documentSelectDialogVisible: false,
      selectedConfigurableDocument: null,
      documentsLoading: false,
      selectedEmployee: null,
      title: "",
      firstTemplateFields: [],
      firstTemplateId: null,
      workflowIconPath: "block-portfolio.svg",
      workFlowPublicURL: "",
      selectedUserId: "",
      hasIntervals: false,
      intervalType: "",
      fequencyDateMap: {
        DAILY: "YYYY-DD-MM",
        WEEKLY: "YYYY-DD-MM",
        MONTHLY: "YYYY-DD-MM",
        YEARLY: "YYYY-DD-MM",
        HOURLY: "YYYY-DD-MM HH:mm",
      },
      formbuilderReciever: "",
      approver: "",
      approvedFormsData: [],
      activeTab: "need_to_be_approved",
      needToApproveCount: 0,
      holdCount: 0,
      approvedCount: 0,
      rejectedCount: 0,
      needToApproveOthersCount: 0,
      reasonForReject: "",
      reasonForRejectModal: false,
      reasonForRejectData: null,
      allEntitiesData: [],
      allCompanyUserTypesData: [],
    };
  },
  computed: {
    ...mapGetters("formBuilders", [
      "getFormBuilderAddStatus",
      "getFormBuilder",
      "getFormBuilderRemindersSettings",
      "getFormBuilderUpdateStatus",
      "getFormBuilderPublicURLDetails",
    ]),
    ...mapGetters("formbuilderData", [
      "getFormbuilderUsersData",
      "getFormbuilderUniqueUsersData",
      "getFormbuilderCreateByContactData",
      "getFormbuilderCreateByContactStatus",
      "getFormbuilderCreateByContactErrors",
      "getFormbuilderDataDeleteStatus",
      "getFormbuilderDataDeleteError",
      "getFormbuilderDataUpdateStatus",
      "getFetchUsersApprovalFormbuilderData",
      "getFetchUsersApprovalFormbuilderCount",
      "getFormbuilderDataUpdateError",
    ]),
    ...mapGetters("templatesData", ["getTemplateFieldsData"]),
    ...mapGetters("companyEntities", ["getSingleCompanyEntity"]),
    ...mapGetters("contacts", ["getAllContacts"]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveWorkspace",
      "getUserType",
      "getUserTypeList",
    ]),
    isCompanyOwner() {
      return this.getFormBuilder.form_builders_owner == "COMPANY";
    },
    paginationProps() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50],
      };
    },
    logoUploadUrl() {
      return config.S3_BUCKET_URL;
    },
    getUserEmail() {
      return (formbuilderData) => {
        if (formbuilderData.contact) {
          return formbuilderData.contact && formbuilderData.contact.email
            ? formbuilderData.contact.email
            : "";
        }
        return "";
      };
    },
    getUserName() {
      return (formbuilderData) => {
        if (formbuilderData.contact) {
          return formbuilderData.contact &&
            formbuilderData.contact.first_name &&
            formbuilderData.contact.last_name
            ? formbuilderData.contact.first_name +
                " " +
                formbuilderData.contact.last_name
            : "";
        }
        return "";
      };
    },
    getContacts() {
      return this.getAllContacts && this.getAllContacts.data
        ? this.getAllContacts.data
        : [];
    },
    isApprovedForm() {
      return true;
    },
  },
  async mounted() {
    let limit = (this.pageSizes && this.pageSizes[0]) || 10;
    this.pageSize = limit;
    [this.allCompanyUserTypesData, this.allEntitiesData] = await Promise.all([
      this.fetchCompanyUsersTypes(),
      this.fetchAllEntities(),
    ]);
    await this.getNecessaryInfo();
    await this.$store.commit("formBuilders/setFormBuilderPrevRouter", null, {
      root: true,
    });
  },

  methods: {
    convertToLocalDateTime(dateTime) {
      const date = new Date(dateTime);
      return date.toLocaleString();
    },
    isAnonymousForm(formbuilderData) {
      return (
        formbuilderData?.formbuilders_details_id?.form_builders_owner ===
        "ANONYMOUS"
      );
    },
    getApprovedUser(formbuilderData) {
      // In priority based approvals, only main approver details should be displayed
      if (formbuilderData?.approval_users && formbuilderData?.priority_based) {
        return formbuilderData?.approval_users.find(
          (e) =>
            (!formbuilderData.priority_based && e.approved_status) ||
            (formbuilderData.priority_based &&
              e.approved_status &&
              e.final_approver)
        );
      } else {
        // In non-priority based(all users need to approve) approvals, all approvers details should be displayed, so returning all the approvers
        return formbuilderData?.approval_users;
      }
    },
    getRejectedUser(formbuilderData) {
      if (formbuilderData?.approval_users) {
        return formbuilderData?.approval_users.find(
          (e) =>
            (!formbuilderData.priority_based && e.rejected_status) ||
            (formbuilderData.priority_based &&
              e.rejected_status &&
              e.final_approver)
        );
      }
      return null;
    },
    checkIsApproverUser(formbuilderData) {
      if (
        formbuilderData?.approval_users &&
        formbuilderData?.approval_status != "APPROVED" &&
        formbuilderData?.approval_status != "REJECTED"
      ) {
        let currentuserIndex = this.getCurrentApprover(formbuilderData);
        if (
          currentuserIndex > -1 &&
          !formbuilderData.approval_users[currentuserIndex].approved_status &&
          !formbuilderData.approval_users[currentuserIndex].rejected_status
        ) {
          return formbuilderData.formbuilder_data_status == "COMPLETED";
        }
        return false;
      }
      return false;
    },
    async onHold(row) {
      this.$confirm("Are you sure to hold the response?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.holdFormbuilder(row);
      });
    },
    async holdFormbuilder(formbuilderData) {
      this.loading = true;
      let currentuserIndex = this.getCurrentApprover(formbuilderData);
      if (currentuserIndex > -1) {
        await this.$store.dispatch("formbuilderData/updateFormbuilderData", {
          approval_status: "HOLD",
          id: formbuilderData._id,
        });
        if (this.getFormbuilderDataUpdateStatus) {
          this.$message({
            message: "Form state updated to hold successfully!",
            type: "success",
          });
          this.fetchAllEmployee();
        } else {
          this.$notify.warning({
            title: "Warning",
            message:
              this.getFormbuilderDataUpdateError.message ||
              "Error while making form hold",
          });
          this.fetchAllEmployee();
        }
      } else {
        this.$notify.warning({
          title: "Warning",
          message: "You are not approver",
        });
      }
      this.loading = false;
    },
    async onApprove(row) {
      this.$confirm("Are you sure to approve the response?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.approveFormbuilder(row);
      });
    },
    async onReject(row) {
      this.$confirm("Are you sure to reject the response?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.rejectFormbuilder(row);
      });
    },
    async approveFormbuilder(formbuilderData) {
      this.loading = true;
      let currentuserIndex = this.getCurrentApprover(formbuilderData);
      if (currentuserIndex > -1) {
        let params = this.getApprovalParams(formbuilderData, currentuserIndex);
        await this.$store.dispatch(
          "formbuilderData/updateFormbuilderData",
          params
        );
        if (this.getFormbuilderDataUpdateStatus) {
          this.$message({
            message: "Approved successfully!",
            type: "success",
          });
          this.fetchAllEmployee();
        } else {
          this.$notify.warning({
            title: "Warning",
            message:
              this.getFormbuilderDataUpdateError.message ||
              "Error while approving",
          });
          this.fetchAllEmployee();
        }
      } else {
        this.$notify.warning({
          title: "Warning",
          message: "You are not approver",
        });
      }
      this.loading = false;
    },
    showRejectConfirm(formbuilderData) {
      this.reasonForRejectData = null;
      this.reasonForReject = "";
      this.reasonForRejectModal = true;
      this.reasonForRejectData = { ...formbuilderData };
    },
    async rejectFormbuilder(formbuilderData) {
      this.loading = true;
      let currentuserIndex = this.getCurrentApprover(formbuilderData);
      if (currentuserIndex > -1) {
        formbuilderData.approval_users[currentuserIndex] = {
          ...formbuilderData.approval_users[currentuserIndex],
          ...{
            rejected_status: true,
            approval_status: true,
            rejected_at: new Date(),
            rejected_by: this.getAuthenticatedUser.email,
            rejected_reason: this.reasonForReject,
          },
        };
        // let isCompleted = true;
        // formbuilderData.approval_users.forEach((e) => {
        //   if (!e?.approved_status && formbuilderData.approve_all_user) {
        //     isCompleted = false;
        //   }
        // });
        let params = this.getRejectParams(
          formbuilderData,
          currentuserIndex,
          this.reasonForReject
        );
        await this.$store.dispatch(
          "formbuilderData/updateFormbuilderData",
          params
        );
        if (this.getFormbuilderDataUpdateStatus) {
          this.$message({
            message: "Rejected successfully!",
            type: "success",
          });
          this.reasonForRejectData = null;
          this.reasonForReject = "";
          this.reasonForRejectModal = false;
          this.fetchAllEmployee();
        } else {
          this.$notify.warning({
            title: "Warning",
            message:
              this.getFormbuilderDataUpdateError.message ||
              "Error while approving",
          });
          this.fetchAllEmployee();
        }
      } else {
        this.$notify.warning({
          title: "Warning",
          message: "You are not approver",
        });
      }
      this.loading = false;
    },
    handleClick() {
      this.fetchAllEmployee();
    },
    printScope() {},
    async getNecessaryInfo() {
      this.loading = true;
      await this.fetchAllEmployee();
      this.loading = false;
    },

    onEdit(row) {
      this.selectedOfferLetterTemplate = row;
      this.editDailogVisible = true;
    },
    async onView(row) {
      this.selectedOfferLetterTemplate = row;
      this.previewDialogVisible = true;
    },
    async onDelete(row) {
      this.$confirm("Are you sure to delete the response?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.deleteTemplate(row);
      });
    },
    async deleteTemplate(row) {
      //deleteFormbuilderData
      await this.$store.dispatch(
        "formbuilderData/deleteFormbuilderData",
        row._id
      );
      if (this.getFormbuilderDataDeleteStatus) {
        this.$message({
          message: "Response deleted successfully!",
          type: "success",
        });
        this.fetchAllEmployee();
      } else {
        this.$notify.error({
          title: "Error",
          message: "Something went wrong. Can't delete.",
        });
      }
    },

    async updateUserQuery(value) {
      this.$router.replace({
        name: this.$route.name,
        params: this.$route.params,
        query: { ...this.$route.query, user_id: value },
      });

      await this.getNecessaryInfo();
    },
    async fetchAllEmployee() {
      this.loading = true;
      this.data = [];
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
        include_template_details: false,
        include_user_details: true,
        include_formbuilder_details: true,
      };
      if (this.activeTab == "hold") {
        params.include_hold = true;
        params.include_need_to_be_approved = true;
      } else if (this.activeTab == "approved") {
        params.include_approval_status = "APPROVED";
      } else if (this.activeTab == "rejected") {
        params.include_approval_status = "REJECTED";
      } else if (this.activeTab == "need_to_be_approved") {
        params.include_need_to_be_approved = true;
      } else {
        params.include_need_to_be_approved = false;
      }
      await Promise.all([
        this.$store.dispatch(
          "formbuilderData/fetchUsersApprovalFormbuilderData",
          params
        ),
        this.$store.dispatch(
          "formbuilderData/fetchUsersApprovalFormbuilderCount",
          {}
        ),
      ]);
      this.loading = false;
      this.currentPage =
        parseInt(this.getFetchUsersApprovalFormbuilderData.page) || 1;
      this.total =
        parseInt(this.getFetchUsersApprovalFormbuilderData.total) || 0;

      if (
        this.getFetchUsersApprovalFormbuilderData.data &&
        this.getFetchUsersApprovalFormbuilderData.data.length
      ) {
        this.data = this.getFetchUsersApprovalFormbuilderData.data;
        this.getFormbuilderUserData(this.data);
        this.approver =
          this.getFetchUsersApprovalFormbuilderData.approver || "";
      } else {
        this.approver = "";
        this.data = [];
      }
      if (this.getFetchUsersApprovalFormbuilderCount.data) {
        this.needToApproveCount =
          this.getFetchUsersApprovalFormbuilderCount.data.pending;
        this.approvedCount =
          this.getFetchUsersApprovalFormbuilderCount.data.approved;
        this.rejectedCount =
          this.getFetchUsersApprovalFormbuilderCount.data.rejected;
        this.needToApproveOthersCount =
          this.getFetchUsersApprovalFormbuilderCount.data.pendingByOthers;
        this.holdCount =
          this.getFetchUsersApprovalFormbuilderCount.data.holdCount;
      }
    },
    backToWorkFlows() {
      this.$router.push({ path: "/formBuilders" });
    },
    parseFirstTemplateData(data) {
      let modifiedData = data.map((e) => {
        let dataObject = {
          _id: e._id,
          name: e.name,
          code: e.code,
          formbuilder_data_status: e.formbuilder_data_status,
          workflow_data_sender_status: e.workflow_data_sender_status,
        };

        let firstTemplateData = e.template_data_ids.find(
          (x) => x.template_id._id == this.firstTemplateId
        );

        if (firstTemplateData) {
          let firstTemplateValues =
            firstTemplateData.template_data_id &&
            firstTemplateData.template_data_id.template_data
              ? firstTemplateData.template_data_id.template_data
              : {};
          dataObject.template_data = firstTemplateValues;
        } else {
          dataObject.template_data = {};
        }

        return dataObject;
      });
      return modifiedData;
    },
    async fetchAllConfigurableDocuments() {
      this.documentsLoading = true;
      await this.$store.dispatch(
        "configurableDocuments/fetchConfigurableDocuments",
        {
          get_all: true,
          type: "FORM",
        }
      );
      this.documentsLoading = false;
    },
    copyURL(url) {
      navigator.clipboard.writeText(url);
      this.$message({
        message: "Link copied successfully!",
        type: "success",
      });
    },

    async generateFormbuilderPublicURL() {
      try {
        this.loading = true;
        await this.$store.dispatch("formBuilders/fetchWorkFlowPublicURL", {
          formbuilder_details_id: this.getFormBuilder._id,
        });

        this.workFlowPublicURL = this.getFormBuilderPublicURLDetails.public_url;
        this.false = true;
      } catch (err) {
        console.log("generateFormbuilderPublicURL", err);
      }
    },
    async loadData(data) {
      if (this.data && this.data.length) {
        this.pageSize = data.pageSize;
        this.page = data.page;
        await this.fetchAllEmployee();
      }
    },

    async selectDocuemnt(doc) {
      this.selectedConfigurableDocument = doc;
    },
    async viewFormbuilderData(row) {
      await this.$store.commit(
        "formBuilders/setFormBuilderPrevRouter",
        window.location.href,
        {
          root: true,
        }
      );
      if (row?.formbuilders_details_id?.code) {
        this.$router.push({
          name: "ApplicationUserFormbuilderView",
          params: {
            formbuilder_code: row.formbuilders_details_id.code,
            formbuilder_data_id: row._id,
          },
          query: {
            origin: "cwdjkrni",
            up: this.checkIsApproverUser(row),
          },
        });
      }
    },
    async editWorkUserData(row) {
      await this.$store.commit(
        "formBuilders/setFormBuilderPrevRouter",
        window.location.href,
        {
          root: true,
        }
      );
      if (row?.formbuilders_details_id?.code) {
        this.$router.push({
          name: "ApplicationUserFormbuilderEdit",
          params: {
            formbuilder_code: row.formbuilders_details_id.code,
            formbuilder_data_id: row._id,
          },
          query: {
            origin: "cwdjkrni",
          },
        });
      }
    },
    getFlowName() {
      return this.getFormBuilder?.name;
    },

    getFieldData(templateData, field) {
      if (templateData[field.key]) {
        if (field.input_type == "DATE") {
          return this.$moment.utc(templateData[field.key]).format("MM-DD-YYYY");
        }
        return templateData[field.key];
      }
      return "-";
    },
  },
  watch: {
    "$route.params.workflow_code": async function () {
      await this.getNecessaryInfo();
    },
    async "$route.query.key"() {
      this.getNecessaryInfo();
    },
    async "$route.params.formbuilder_code"() {
      this.getNecessaryInfo();
    },
  },
};
</script>

<style lang="scss" scoped>
.add-buttons {
  border: 1.5px solid #285ed3;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 0.45em 0.65em;
  color: #285ed3;
  &:hover {
    background-color: #285ed3;
    color: #ffffff;
    border-color: #285ed3;
  }
}
#workflow-users-list {
  width: 90%;
  display: table;
  margin: 0 auto;
  .el-dialog__body {
    padding: 20px 20px;
    .el-card {
      .el-card__body {
        padding: 10px;
      }
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}
.doc {
  min-width: 30px;
  &.selected {
    background: green;
  }
}

.green {
  color: green;
}

.font-17 {
  font-size: 17px;
  cursor: pointer;
}

.documents {
  min-height: 200px;
  max-height: 400px;
  overflow: scroll;
}
.el-table .cell {
  word-break: break-word;
}
.el-table .td {
  word-wrap: break-word;
}
.count-circle {
  display: inline-block;
  width: 24px;
  height: 24px;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  position: relative;
  left: 4px;
}
.approver-text {
  color: #3366cc;
}
.list-style {
  margin-top: 10px;
}

.expired-div {
  color: #dc3545;
}
</style>
